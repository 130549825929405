import React from 'react'

import ExodusSymbol from 'static/img/exodus-symbol.svg'
import 'static/404/scss/styles.scss'

const Header = () => (
  <div className="x-download__note-header">
    <div className="x-download__note-header-content">
      <a href="/">
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12">
          <path
            fill="none"
            fillRule="evenodd"
            stroke="#FFF"
            strokeWidth="1.5"
            d="M6 11L1 6l5-5"
            opacity=".8"
          />
        </svg>
        Back to the main website
      </a>
      <img src={ExodusSymbol} className="logo-container" />
    </div>
  </div>
)

const Content = () => (
  <div className="x-notfound x__width">
    <span className="x-notfound__subheading">404</span>
    <h1 className="x-notfound__heading">Page Not Found</h1>

    <p className="x-notfound__txt">
      Looks like you've followed a broken link or entered a URL that doesn't exist on this site.
    </p>

    <div className="x-notfound__buttons">
      <a className="x__button x__button--secondary" href="https://support.exodus.com/">
        <span className="x__button-align">
          <span className="x__button-align-text">Visit our Support page</span>
        </span>
      </a>

      <a href="/" className="x__button">
        <span className="x__button-align">
          <span className="x__button-align-text">Go to Exodus home page</span>
        </span>
      </a>
    </div>
  </div>
)

export default function NotFoundPage(props) {
  return (
    <div className="x-page-notfound">
      <main className="x">
        <Header />
        <Content />
      </main>
    </div>
  )
}

export function Head() {
  return (
    <>
      <title>404: Page Not Found</title>
      <meta name="description" content="Page Not Found" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="robots" content="noindex" />
      <meta name="googlebot-news" content="noindex" />
      <meta httpEquiv="cache-control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="expires" content="0" />
      <meta httpEquiv="pragma" content="no-cache" />
      <link
        rel="preload"
        as="font"
        href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,500"
      />
    </>
  )
}
